export default {
  methods: {
    async goToAdminPanel (isAdmin, token) {
      const lang = await this.$cookies.get('lang') || 'am'
      const _token = this.$cookies.get('accessToken')
      const path = 'https://admin.goldcenter.am' // 'http://localhost:3001' //
      if (isAdmin && process.browser) {
        window.location = `${path}?token=${token || _token}&lang=${lang}`
      }
    }
  }
}
