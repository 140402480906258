import { currencys } from '../utils/currencys'
export default {
  mounted () {
    this.onChangeCookie = setInterval(() => {
      const currency = this.$cookies.get('currency')
      const felem = this.currencys.find(x => x.code === currency)
      if (felem && this.activeCurrency.code !== currency) {
        this.activeCurrency = felem
        this.renderCurrencysProduct =
          currencys.filter(e => e.code !== currency) || []
      }
    }, 100)
  },
  beforeDestroy () {
    clearInterval(this.onChangeCookie)
  },
  data () {
    return {
      languages: this.$i18n.locales,
      activeCurrency: {},
      currencys,
      renderCurrencysProduct: []
    }
  },
  computed: {
    selectedLanguage () {
      return this.languages.find(x => x.code === this.$i18n.locale)
    },
    activeCurrencyGetters () {
      const currency = this.$cookies.get('currency')
      const felem = this.currencys.find(x => x.code === currency)
      if (felem) {
        this.activeCurrency = felem
        return this.activeCurrency
      }
      this.activeCurrency = {
        label: 'amd',
        code: 'amd'
      }
      this.$cookies.set('currency', 'amd')
      // this.$cookies.set('currency', 'amd', {
      //   domain: '.goldcenter.am'
      // })
      return this.activeCurrency
    }
  },
  methods: {
    removeLangCodeFromPath (path) {
      // Improved regex to match language code at the beginning of the path
      const langCodeRegex = /^\/\w+\/?/
      path = path.replace(/#/g, '')
      const newPath = path.replace(langCodeRegex, '')

      // Ensure we don't leave a leading slash if newPath is empty
      return newPath.startsWith('/') ? newPath.substring(1) : newPath
    },
    selectLanguage (lang) {
      if (!lang) { return }
      const currentPath = this.$route.path
      const newPath = this.removeLangCodeFromPath(currentPath)

      // Check if newPath is empty and handle default language redirection
      const newUrl = lang.code === 'en' && !newPath
        ? '/'
        : `/${lang.code}/${newPath}`
      console.log(this.$route.query)
      try {
        this.$router.push({
          path: newUrl,
          query: this.$route.query
        })
        this.$i18n.setLocale(lang.code)
        // Locale change successful
      } catch (err) {
        // Handle any other potential errors
        console.error('Error:', err)
      }
    },
    changeActiveCurrency (currency) {
      this.activeCurrency = currency
      this.$cookies.set('currency', currency.code)
      // this.$cookies.set('currency', currency.code, {
      //   domain: '.goldcenter.am'
      // })
      this.$store.commit('ui/setCurrency', currency.code)
    }
  }
}
