import { mapActions } from 'vuex'
export default {
  mounted () {
    const sessionsId = this.$cookies.get('session_id')
    if (sessionsId || this.currentUser) {
      this.getCarts()
    }
  },
  methods: {
    ...mapActions({
      getCartRequest: 'cart/get',
      removeCard: 'cart/remove'
    }),
    async getCarts () {
      const sendData = {
        limit: 100,
        offset: 0,
        session_id: await this.$cookies.get('session_id')
      }
      this.getCartRequest(sendData)
    },
    async removeCart (id) {
      await this.removeCard({
        id,
        session_id: await this.$cookies.get('session_id')
      })
      await this.getCarts()
    }
  }
}
